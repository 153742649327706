import React from "react"
import { Link } from "gatsby"
import JSONData from "../content/index.json"

const IndexList = () => (
  <section id="exhibitions" className="wrapper">
    <h2>{JSONData.title}</h2>
    <div className="tbHeader">
      <div className="row">
        <span>Event</span>
        <span>Project</span>
        <span>Location</span>
        <span>Year</span>
      </div>
    </div>
    <div className="tbBody">
      {JSONData.content.map((data, index) => {
        return (<div data-sal="slide-up" className="row" key={`content_item_${index}`}>
        <span>{data.evtLink ? <a href={data.evtLink}>{data.evt}</a> :  data.evt }</span>
        <span>{data.projLink ? <Link to={data.projLink}>{data.proj}</Link> : data.proj }</span>
        <span>{data.location}</span>
        <span>{data.year}</span>
        </div>)
      })}
    </div>
  </section>
)
export default IndexList
