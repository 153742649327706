import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import '../components/layout.css';
import '../components/image.module.css';

import IndexList from '../components/indexList';

const Index = ({ data }) => {
  const posts = data.postList.nodes;
  const imgStyle = {
    zIndex: '10',
    boxShadow: '1px 1px 9px rgba(12, 12, 12, 0.125)',
    border: '12px solid rgb(255, 255, 255)',
  };
  return (
    <Layout>
      <Seo title="Projects" />
      <section id="about" className="wrapper">
        <h2>Hello,</h2>
        <p>
        I am Lucia Dossin (BR/NL), developer and designer living in Rotterdam, the Netherlands. I hold a BA in Architecture & Urbanism from FAU USP, a background in (self-taught) webdesign practices and a MA in Media Design & Communication from Piet Zwart Institute. For about 15 years <a href="https://fiveblackcats.com">I have been working with (web)design and development</a> and recently I started exploring some insights and intuitions acquired throughout my work path from an artistic perspective.
         </p>

        <p>
        My artistic work resides at the intersection of art and design and deals with elements that shape the actual, possible and desirable relationships between humans and computers: user-friendliness, interfaces, user-generated data and technological literacy. For a more insightful description of what I do, and why, check my <Link to="/statement">statement</Link>.</p>
      </section>
      <section id="work" className="wrapper">
        <h2>Work</h2>
        <p>
        Below you will find an overview of my artistic work. For my full porftolio, including selected commisioned commercial work, please visit <a href="https://fiveblackcats.com">Fiveblackcats.com</a>.
        </p>
        <div className="tbHeader" />
        <div className="tbBody">
          {posts.map((post, index) => (

            <div data-sal="slide-up" className="row" key={`${post.id}`}>

              {post.featuredImage && (

                <Link to={`/projects/${post.slug}`} title={`/projects/${post.slug}`}>
                  <GatsbyImage
                    image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                    alt={`Illustrative image for "${post.title}"`}
                    style={imgStyle}
                  />
                </Link>
              )}

              <h3><Link to={`/projects/${post.slug}`} title={`/projects/${post.slug}`}>{post.title}</Link></h3>
              <p>{`${post.excerpt}`.replace('<p>', '').replace('</p>', '')}</p>
              <p className="small index-meta">
                <span>{post.projectInfo.fbcProjData}</span>
                { ' ' }
                {post.tags.nodes.map((tag, ind) => [
                  ind > 0 && ', ',
                  tag.name,
                ])}
              </p>

            </div>

          ))}

        </div>
      </section>
      <IndexList />
    </Layout>
  );
};

export default Index;

export const projQuery = graphql`
  query {
    postList: allWpProject(
      filter: {projectInfo: {fbcProjHighlight: {eq: true}}}
      sort: {fields: projectInfo___fbcProjData, order: DESC}
    ) {
      nodes {
        title
        excerpt
        slug
        projectInfo {
          fbcProjData
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`;
